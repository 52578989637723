//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    value: {
      type: Boolean,
      required: true
    },
  },
  watch: {
    value (isOpen) {
      if (!isOpen) {
        this.useEmail = false
      }
    }
  },
  methods: {
    hide () {
      this.$emit('input', false)
    }
  }
}
